import Vue from 'vue'
import App from './App.vue'
import "@/assets/font/font.css"
import router from './router'
import less from 'less'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import animate from 'animate.css'



Vue.use(animate)
Vue.use(VueAwesomeSwiper)

Vue.use(less)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
