<template>
  <div class="tab" @mouseleave="outTab()" >
    <div class="logo">
      <img @click="goPage('/')" src="@/assets/logo.png" alt="" />
    </div>
    <div class="iphone-css" @click="IphoneTabType=!IphoneTabType">
      三
    </div>
    <div class="tab-list" v-if="IphoneTabType">
      <div class="tab-btn-list" :class="{ setpage1: $route.path == name.path }"
      v-for="(name, i) in tablist" :key="i"
      @click="goPage(name.path),IphoneTabType=false" >
        {{name.name}}
      </div>
      <div class="tab-btn-list"
      @click="IphoneTabType=false,pcpop = !pcpop" >
      ENQUIRY
      </div>
    <!-- <div class="close-tab" @click="IphoneTabType=false">X</div> -->
    </div>

    <div class="pages">
      <!-- v-if="itme.name != 'ENQUIRY'" -->
      <div
        class="tab-page"
        @click="goPage(itme.path)"
        v-for="(itme, i) in tablist"
        :key="i"
      >
        <span class="tab-text" :class="{ setpage: $route.path == itme.path }">{{ itme.name }}</span>
        <span class="tab-interval"  v-if="itme.name != 'ENQUIRY'"> </span>
      </div>
    </div>
    <div class="tab-page enquiry-tab" style="margin-right: 20px;">
        <span @click="pcpop = !pcpop" class="tab-text" style="cursor: pointer;color: white;border: 2px solid #f1ece7;border-radius: 25px;padding: 6px 12px;">
          ENQUIRY
        </span>
        <div v-if="pcpop" class="contant-pop">
          <img ondragstart="return false;" src="@/assets/contant.png" alt="">
        </div>
      </div>
      <div v-if="pcpop" class="iphone-contant-pop-div" style="position: fixed;top: 0px;width: 100%;height: 100%;background: #0000005c;">
        <div class="iphone-contant-pop">
              <img style="margin-top: 35px;" src="@/assets/contant.png" alt="">
            <div @click="pcpop=false" style="font-weight: bold;border: 2px solid #080808;border-radius: 50%;width: 30px;height: 30px;line-height: 30px;margin: 35px auto;">x</div>
        </div>
      </div>

  </div>
</template>


<script>
export default {
  data() {
    return {
      pcpop:false,
      Iphonepop:false,
      IphoneTabType:false,
      tablist: [
        // {path: "/",name: "Project Info",meta: {showtype: true,showTab: true,},},

        {path: "/location",name: "LOCATION",meta: {showtype: true,showTab: true,},},
        {path: "/about",name: "DEVELOPER",meta: {showtype: true,showTab: true,},},
        {path: "/floorplan",name: "FLOOR PLAN",meta: {showtype: true,showTab: true,},},
        {path: "/gallery",name: "GALLERY",meta: {showtype: true,showTab: true,},},
        // {path: "/siteplan",name: "Site Plan",meta: {showtype: true,showTab: true,},},
        {path: "/vr",name: "VIRTUAL TOUR",meta: {showtype: true,showTab: true,},},
        {path: "/brochure",name: "E-BROCHURE",meta: {showtype: true,showTab: true,},},
        // {path: "/ENQUIRY",name: "ENQUIRY",meta: {showtype: true,showTab: true,},},

        
      ],
    };
  },
  methods: {
    outTab(){
      this.pcpop = false
    },
    overTab(i){
      this.pcpop = true
    },
    goPage(pagePath) {
      if (pagePath == '/ENQUIRY') {
        this.pcpop = true
        return
      }else if(pagePath == '/floorplan'){

      }
      this.pcpop = false
      this.tabindex = pagePath;
      // console.log(pagePath);
      this.$router.push(pagePath).catch((err) => {});
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.tab {
  width: 100%;
  height: 10%;
  background-color: #856648;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 121;
  font-family: 'GOTHIC';
  .logo {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name:backInLeft; /*fadeInLeft为要使用的动画效果名，在这里不需要加animate前缀*/
    animation-duration: 2s; /*这里设定完成该动画的时间*/
    img{
      height: 80%;
    }
  }
  .iphone-css{
    display: none;
    color: white;
    padding: 10px;
    font-size: 1.7rem;
  }
  .iphone-contant-pop{
    display: none;
  }
  .contant-pop{
    z-index: 1001;
    position: absolute;
    top:10%;
    width: 500px;
    background: white;
    padding: 10px;
    border-radius: 11px;
    transform: translate(-80%, 0);
    box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.3);
    img{
      max-width: 100%;
    }
  }

  .pages {
    width: 65%;
    display: flex;
    flex-direction: row;
    // .tab-page:nth-child(5) .tab-text{
    //   border: 2px solid #f1ece7;border-radius: 25px;padding: 6px 12px;
    // }
    .tab-page {
      line-height: 100%;
      font-size: 1.2rem;
      text-align: center;
      margin: auto 0;
      color: rgb(197, 197, 197);
      cursor: pointer;
      .tab-interval {
        padding: 0 1.5rem;
      }

    }
  }
  .setpage1{
    color: white !important;
    font-weight: bold;
    background: #ffffff3d !important;
    // font-family: 'GOTHICB';
  }
  .setpage {
    color: white !important;
    padding-bottom: 7px;
    font-weight: bold;
    border-bottom: 3px solid #f5f5f5;
    // font-family: 'GOTHICB';
  }

  
  .tab-list{
    position: absolute;
    top: 10%;
    right: 0px;
    width: 40%;
    display: flex;
    flex-flow: column;
    align-items: baseline;
    text-align: start;
    font-weight: bold;
    background: #856648;
    animation-name:slideInRight; /*fadeInLeft为要使用的动画效果名，在这里不需要加animate前缀*/
    animation-duration: 0.3s; /*这里设定完成该动画的时间*/
    z-index: 120;
  .tab-btn-list{
    padding: 10px;
    color: white;
    width: 100%;
  }
  .tab-btn-list:hover{
    padding: 8px;
    color: black;
    background: white;
  }

  }
  .iphone-contant-pop-div{
    display: none;
  }
}

@media screen and (max-width: 800px){
  .enquiry-tab{
    display: none;
  }
  .iphone-contant-pop-div{
    display: block !important;
  }
  .iphone-contant-pop{
    z-index: 1001;
    display: block !important;
    position: absolute;
    width: 90%;
    background: white;
    padding: 10px;
    border-radius: 11px;
    transform: translate(-50%, -50%);
    top:50%;
    left: 50%;
    box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.3);
  }
  .logo{
    width: 45% !important;
  }
  .iphone-css{
    display: flex !important;
  }
  .pages {
    display: none !important;
  }
}
</style>






