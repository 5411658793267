<template>
  <div id="app">
    <tab/>
    <router-view/>

    </div>
</template>


<script>
import tab from "@/components/tab.vue"

export default {
  data() {
    return {
    }
  },
  components:{
    tab
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="less">


html,body,#app{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  background-color: white;
}
img{
      max-width: 100%;
      max-height: 100%;
    }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

// 滚动条样式
* {
  ::-webkit-scrollbar {
    width: 1px;
    background-color: #b6d9e200;
  }
  ::-webkit-scrollbar:hover{
    width: 10px !important;
  }
  ::-webkit-scrollbar-track {
    width: 1px;
    background-color: #b6d9e200;
  }
  ::-webkit-scrollbar-thumb {
    // width: 1px;
    padding: 2px;
    border-radius: 6px;
    height: 120px;
    background-color: rgb(0, 0, 0);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(83, 83, 83);
    width: 30px !important;

  }
  ::-webkit-scrollbar-thumb:active {
    background-color: rgb(83, 83, 83);
  }
}



</style>
