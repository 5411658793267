import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {path: '/',name: 'INTRODUCTION',component: () => import(/* webpackChunkName: "about" */ '../views/info.vue')},
  {path: '/location',name: 'INTRODUCTION',component: () => import(/* webpackChunkName: "about" */ '../views/location.vue')},
  {path: '/siteplan',name: 'INTRODUCTION',component: () => import(/* webpackChunkName: "about" */ '../views/siteplan.vue')},
  {path: '/floorplan',name: 'INTRODUCTION',component: () => import(/* webpackChunkName: "about" */ '../views/floorplan.vue')},
  {path: '/gallery',name: 'INTRODUCTION',component: () => import(/* webpackChunkName: "about" */ '../views/gallery.vue')},
  {path: '/about',name: 'INTRODUCTION',component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')},
  {path: '/home',name: 'INTRODUCTION',component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')},
  {path: '/brochure',name: 'INTRODUCTION',component: () => import(/* webpackChunkName: "about" */ '../views/brochure.vue')},
  {path: '/vr',name: 'INTRODUCTION',component: () => import(/* webpackChunkName: "about" */ '../views/vr.vue')},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
